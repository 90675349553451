import { Flex, NumberInput, Stack, TextInput } from "@mantine/core";
import type { Project } from "../../generated";
import { useTimeReportStore } from "./store";

import { isWeekend, format } from "date-fns";

import classes from "./project-row.module.css";

import Duration from "@icholy/duration";
import { type DateRange, isLocked, isProjectLocked } from "./helpers";

import { ProjectRowHeader } from "./project-row/header";
import { TimeInput } from "./project-row/time-input";

type Props = {
  dates: DateRange[];
};

export const SummaryRow = ({ dates }: Props) => {
  const { reportedTime } = useTimeReportStore();

  const totalTime = reportedTime.reduce(
    (acc, curr) => acc + new Duration(curr.time).hours(),
    0,
  );

  return (
    <Stack gap={8} py="md">
      <Flex className={classes.projectRow}>
        {dates.map(({ date, dayOff }) => {
          const value = reportedTime
            .filter(
              (r) => new Date(r.date).toISOString() === date.toISOString(),
            )
            .reduce((acc, curr) => acc + new Duration(curr.time).hours(), 0);

          return (
            <TextInput
              key={date.getTime()}
              value={value}
              classNames={{ input: classes.dayTotal }}
              className={classes.date}
              disabled
              wrapperProps={{
                "data-date": format(date, "MMM d"),
              }}
            />
          );
        })}
        <div className={classes.total}>
          <NumberInput
            value={totalTime}
            className={classes.date}
            wrapperProps={{
              "data-date": "Total",
            }}
            disabled
            hideControls
            classNames={{ input: classes.totalInput }}
          />
        </div>
      </Flex>
    </Stack>
  );
};
